import { Link } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import '../../src/App.css'
import Nav from './Nav';

export const Header = ({ siteTitle }) => {
  return (
    <div className="cards-width header">
      <Helmet>
        <title>phillipmorrow.com</title>
      </Helmet>
      <span className='float-to-right'>
        <a
          href="https://github.com/phillip-morrow"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="../../github.png" className="iconSize" alt="github"></img>
        </a>
        <a
          href="https://www.linkedin.com/in/phillip-m-2552ba5b/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="../../linkedin.png" className="iconSize" alt="linkedin"></img>
        </a>
        <a
          href="https://fosstodon.org/@pmorrowtron"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="../../mastodon.png" className="iconSize" alt="mastodon" />
        </a>
        <a
          href="https://www.codewars.com/users/pmorrowtron"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="../../codewars.svg" className="iconSize" alt="codewars" />
        </a>
      </span>
      <span>
        <Link to="/">
          <h1 className='site-title'>{siteTitle}</h1>
        </Link>
      </span>
      <Nav />
    </div>
  )
};