import React from "react";
import 'font-awesome/css/font-awesome.min.css';
import { Link } from "gatsby";

const Nav = () => {
  const hamberder = () => {
    let cheeseberder = document.getElementById("myTopnav");
    if (cheeseberder.className === "topnav") {
      cheeseberder.className += " responsive";
    } else {
      cheeseberder.className = "topnav";
    }
  };

  return (
    <div className="topnav" id="myTopnav" style={{marginBottom: "-2px"}}>
      <a href="/">
        home
      </a>
      <Link to="/pens">code</Link>
      <Link to="/blog">blog</Link>
      <Link to="/photos">photos</Link>
      <Link to="/videos">videos</Link>
      <Link to="/boards">draw</Link>
      <Link to="/about">about</Link>
      <a className="icon" href="#" onClick={hamberder}>
        <i className="fa fa-bars"></i>
      </a>
    </div>
  );
};

export default Nav;
